import { dietaDto } from '@/shared/dtos/visualizacion_dieta/dietaDto';
import { ssmMessageService } from '@/shared/services/message-service';
import dieta_visualizacionModule from '@/store/modules/visualizacion_dieta/dieta_visualizacion-module';
import { UtilsNotify } from '@/utils/utils-notify';

export default class SerutilDieta {
public retirar_de_app(id:number,vue_instacia:any) {
    var dieta: any = dieta_visualizacionModule.dietas.find(
      x => x.id === id
    )!;

    if (!dieta.visible_paciente) {
      ssmMessageService.toastinfo("Esta dieta no esta enviada al paciente");
      return;
    }
    //@ts-ignore
    vue_instacia.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: `Confirme`,
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text:
        "¿Seguro que quiere borrar esta dieta de la aplicación del paciente?",
      accept: () => {
        dieta.visible_paciente = false;
        dieta = new dietaDto(dieta);
        dieta_visualizacionModule
          .send_dieta_paciente(dieta)
          .then(() =>
            UtilsNotify.NotificacionSuccess(
              "Dieta retirada",
              "Se ha borrado correctamente la dieta de la app del paciente"
            )
          );
      }
    });
  }
  public enviar_a_app(id:number) {
    var dieta: any = dieta_visualizacionModule.dietas.find(
      x => x.id === id
    )!;

    if (dieta.visible_paciente) {
      ssmMessageService.toastinfo("La dieta ya está enviada al paciente");
      return;
    }
    dieta.visible_paciente = true;
    dieta = new dietaDto(dieta);
    dieta_visualizacionModule
      .send_dieta_paciente(dieta)
      .then(() =>
        UtilsNotify.NotificacionSuccess(
          "Dieta enviada",
          "Se ha enviado correctamente la dieta al paciente"
        )
      );
  }
}

export const DietaUtilsService = new SerutilDieta();