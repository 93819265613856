




































































































































































import { Component, Vue } from "vue-property-decorator";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsString } from "@/utils/utils-string";
import { dietaDto } from "@/shared/dtos/visualizacion_dieta/dietaDto";
import { UtilsNotify } from "@/utils/utils-notify";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { paciente } from "@/shared/dtos/paciente-dto";
import pacienteModule from "@/store/modules/paciente-module";
import entrevistaModule from "@/store/modules/entrevista-module";
import { DietaUtilsService } from "./service-dieta-utils";
import { modelo } from "@/shared/dtos/platillas-modelos/modelo";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    PacientesSeleccion: () =>
      import("@/views/pacientes/pacientes-seleccion.vue"),
    Seleccionplantillas: () =>
      import("@/views/plantillas/seleccion-plantillas.vue")
  }
})
export default class Hist_Dietas extends Vue {
  public id_paciente!: number;
  public more_options_for: number = 0;
  public show_dialog_more_options: boolean = false;
  public show_dialog_paciente: boolean = false;
  public pacienteSeleccionado: paciente = new paciente();
  public show_dialog_add_plantilla: boolean = false;
  public show_select_platilla: boolean = false;
  public platilla: modelo = new modelo();
  public created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
      dieta_visualizacionModule.getdietas_paciente(this.id_paciente);
      entrevistaModule.getentrevistas(this.id_paciente);
    }
  }

  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Editar",
        EventMethod: this.editarDietaMore
      })
    );
    opts.push(
      new buttonOptions({
        id: 2,
        title: "Eliminar",
        EventMethod: this.EliminarDietaMore
      })
    );
    opts.push(
      new buttonOptions({
        id: 3,
        title: "Clonar/Duplicar",
        EventMethod: this.ClonarDietaMore
      })
    );
    opts.push(
      new buttonOptions({
        id: 4,
        title: "Asignar a otro paciente",
        EventMethod: this.Aignar_A
      })
    );
    opts.push(
      new buttonOptions({
        id: 5,
        title: "Enviar a app paciente",
        EventMethod: this.enviar_a_app
      })
    );

    opts.push(
      new buttonOptions({
        id: 6,
        title: "Retirar dieta de la app del paciente",
        EventMethod: this.retirar_de_app
      })
    );

    opts.push(
      new buttonOptions({
        id: 7,
        title: "Convertir dieta en plantilla",
        EventMethod: this.convertir_en_platilla
      })
    );
    return opts;
  }

  public get dietas() {
    return dieta_visualizacionModule.dietas;
  }

  public get tiene_entrevistas_hechas() {
    return entrevistaModule.entrevistas.length > 0;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha dieta",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );

    Columnas.push(
      new ClassColumnDataTable(
        "porcen_hc",
        "Glúcidos",
        datatypes.number,
        true,
        datatypes.maxlengthinteger
      ).CreateTemplate("cell-template-1")
    );

    Columnas.push(
      new ClassColumnDataTable(
        "porcen_pr",
        "Proteínas",
        datatypes.number,
        true,
        datatypes.maxlengthinteger
      ).CreateTemplate("cell-template-2")
    );

    Columnas.push(
      new ClassColumnDataTable(
        "porcen_gr",
        "Grasas",
        datatypes.number,
        true,
        datatypes.maxlengthinteger
      ).CreateTemplate("cell-template-3")
    );
    Columnas.push(
      new ClassColumnDataTable(
        "energia",
        "Kcal",
        datatypes.number,
        true,
        datatypes.maxlength
      ).ChangeWidth(100)
    );
    Columnas.push(
      new ClassColumnDataTable(
        "visible_paciente",
        "Enviado a la app",
        datatypes.boolean,
        true,
        datatypes.maxlength
      ).ChangeWidth(120)
    );
    return Columnas;
  }
  public editarDietaMore() {
    this.editarDieta(this.more_options_for);
    this.show_dialog_more_options = false;
  }
  public EliminarDietaMore() {
    this.Eliminar(this.more_options_for);
    this.show_dialog_more_options = false;
  }
  public ClonarDietaMore() {
    this.Clonar(this.more_options_for);
    this.show_dialog_more_options = false;
  }
  public Aignar_A() {
    this.show_dialog_more_options = false;
    this.show_dialog_paciente = true;
  }
  public retirar_de_app() {
    this.show_dialog_more_options = false;
    DietaUtilsService.retirar_de_app(this.more_options_for, this);
  }
  public enviar_a_app() {
    this.show_dialog_more_options = false;
    DietaUtilsService.enviar_a_app(this.more_options_for);
  }
  public async Aceptar_nueva_plantilla() {
    this.platilla.id_dieta = this.more_options_for;
    const modelos_module = await import("@/store/modules/modelo-module");
    modelos_module.default.ConveritirDietaEnModelo(this.platilla).then(() => {
      this.platilla = new modelo();
      this.show_dialog_add_plantilla = false;
      UtilsNotify.NotificacionSuccess(
        "Plantilla creada",
        "Se ha cerado la plantilla correctamente"
      );
    });
  }
  public convertir_en_platilla() {
    this.show_dialog_more_options = false;
    this.show_dialog_add_plantilla = true;
  }

  public Aceptar() {
    dieta_visualizacionModule
      .Asignar_dieta_a({
        id_dieta: this.more_options_for,
        id_paciente: this.pacienteSeleccionado.numero
      })
      .then(() => (this.show_dialog_paciente = false));
  }

  public editarDieta(id_dieta: number) {
    this.$router.push({
      name: RouterNames.dieta,
      query: {
        id_dieta: UtilsEncript.Encriptar(id_dieta.toString()),
        id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString())
      }
    });
  }

  public crear_nueva_dieta() {
    dieta_visualizacionModule
      .getIdDieta(this.id_paciente)
      .then((x: dietaDto) => {
        this.$router.push({
          name: RouterNames.dieta,
          query: {
            id_dieta: UtilsEncript.Encriptar(x.id.toString()),
            id_paciente: UtilsEncript.Encriptar(x.paciente.toString())
          }
        });
      });
  }

  public async crear_nueva_dieta_automatica() {
    await entrevistaModule.getultimaentrevista(this.id_paciente);
    this.$router.push({
      name: RouterNames.asistente_dieta_automatica,
      query: {
        id_cita: UtilsEncript.Encriptar(
          UtilsString.ValueOf(entrevistaModule.entrevista.id_cita).toString()
        ),
        id_entrevista: UtilsEncript.Encriptar(
          UtilsString.ValueOf(entrevistaModule.entrevista.id).toString()
        ),
        id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString())
      }
    });
  }

  public asignar_platilla() {
    this.show_select_platilla = true;
  }

  public async Eliminar(rw: any) {
    await dieta_visualizacionModule.eliminardieta_visualizacion(rw).then(() => {
      UtilsNotify.NotificacionSuccess("Dieta eliminada correctamente", "");
      dieta_visualizacionModule.getdietas_paciente(this.id_paciente);
    });
  }
  public async Clonar(id: any) {
    await dieta_visualizacionModule.Clonar_dieta(id).then(() => {
      UtilsNotify.NotificacionSuccess("Dieta clonada correctamente", "");
      dieta_visualizacionModule.getdietas_paciente(this.id_paciente);
    });
  }
  public async MoreOptions(id: any) {
    if (pacienteModule.pacientes.length === 0) {
      pacienteModule.getpacientes();
    }
    this.more_options_for = id;
    this.show_dialog_more_options = true;
  }
}
